.interviewCalendar-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: #e6edf1;

    .interviewCalendar-Left-wrapper {
        padding: 5px;
        justify-content: space-between;
        align-items: center;
        margin:5px;
        width: 60%;
        height: 50%;
        border: solid 1px #dae6ee;
        ul {
            list-style-type: none;
        }
        .interviewCalendar-Left-item1 {
           height: 50%;
        }
    }
    .interviewCalendar-Right-wrapper {
        width:40%;
    }
}