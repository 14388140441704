.superuserplan-selectable-wrapper {
  margin-top: 30px;
  width: auto;
  font-family: sans-serif;

  .rtable-title-wrapper {
    display: grid;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .superuserPlanTableSelectable-title {
      background-color: #b8e0ff;
      height: 60px;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 650;
      text-align: center;
      border: dotted;
      border-width: 1px;
      border-color: #b1b4b6;
      overflow: hidden;

      &:first-child {
        border-top-left-radius: 1px;
      }
      &:last-child {
        border-top-right-radius: 1px;
      }
    }
  }
  overflow: hidden;
  .rtable-info-wrapper {
    display: grid;

    &:last-child {
      .jd-info {
        &:first-child {
          border-bottom-left-radius: 1px;
        }

        &:last-child {
          border-bottom-right-radius: 1px;
        }
      }
    }

    .rtable-info {
      padding: 8px 8px;
      background-color: #e6f4ff;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: wrap;
      white-space: wrap;
      text-align: center;
      font-size: 10px;
      font-weight: 600;
      border: solid;
      border-width: 1px;
      border-color: rgb(229, 231, 235);

      .edit-field-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        .edit-field-active {
          .field-input {
          }

          .btn-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            .btn-confirm {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: green;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: rgba(0, 128, 0, 0.753);
              }
              width: 40%;
            }

            .btn-cancel {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40%;
            }
          }
        }

        .edit-icon {
          width: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .rtable-info-select {
    padding: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }
  .rtable-selectable-btn-wrapper {
    .rtable-selectable-btn {
      background-color: #ffffff;
      margin-bottom: 10px;
    }
  }
}
