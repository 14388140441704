.landing-wrapper{
    display: flex;
    flex-direction: column;
    gap:2px;
   
    .landing-header{
      padding: 20px;
      display: flex;
      flex-direction: row; 
      height: 80px;
      width: auto;
      font-size: 12px;
      font-weight: 550;
      justify-content: space-between;
      gap: 50px;
      background-color:white;
      align-items: center;

    .landing-header-left {
      display: flex;
      flex-direction: row;
      gap: 50px;
      align-items: center;
    }


      .header-logo{
        align-items: center;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .header-logo-img{
            margin-top: 30px;
            height: 120px;
            width: 120px;
        }
        .header-logo-name{
           font-size: 14px;
        }
    }
      .header-menu{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap:55px; 
        list-style-type: none;
      }
      .header-sign-old{
        display: flex;
        flex-direction: row;  
        margin-top: 3px;
        gap: 5px;

        .sign-button{
            font-size: 12px;
            font-weight: 550;
        }
        .job-button{
            font-size: 12px;
            font-weight: 550;
        }
      }
    }

    .landing-main-slider{
      padding: 5px;
      display: flex;
      flex-direction: row;
      gap:80px;
      height: 80vh;
      background-color: rgb(240, 243, 248);
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      

      .punch-heading-wrapper{
        text-align: center;
        margin: 5vh;
        display: flex;
        flex-direction: column;
        gap:5px;

        .punch-heading{
            margin-top: 100px;
            font-size: 5vh;
            width:80%;
            font-weight: 600;
            align-self: center;

        }
        .punch-sub-heading{
            align-self: center;
            font-size: 14px;
            font-weight: 500;
            width: 60%
        }

        .request-button{
            align-self: center;
            margin-top: 60px;
            font-size: 15px;
            font-weight: 550;
            width:150px;
            height: auto;
            padding: 5px;
            background-color: rgb(60, 229, 153);
        }
        .stats{
            display: grid;
            margin-top: 80px;
            grid-template: 40px / auto auto auto auto auto;
            background-color: rgb(234, 242, 242);
            .stats-data{
                align-self:last baseline;
                margin-bottom: 4px;
                font-size: 12px;
                color: rgb(170, 176, 170);
                font-weight: 800;

                .stats-image{
                    margin:10px;
                    height:20px;
                    width:20px;
                }
            }
        }        
      }

      .main-image-wrapper{
        width:50%;
        align-self: center;
        display: flex;
        flex-direction: column;
        .main-image{
            align-self: center;
            margin-top: 30px;
            height: 400px;
            width: 500px;
        }
      }
    }
    .landing.products{
      padding: 5px;
    }
    .landing-pricing{
      padding: 5px;
    }
    .landing-forWho{
        padding: 5px;
        align-self: center;
    }    
    .landing-features{
        padding: 5px;
        align-self: center;
    }
    .landing-support{
        padding: 5px;
    }
    .landing-footer{
        padding: 5px;
    }
}

.header-left-link {
  text-decoration: none;
}
