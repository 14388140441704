.jd-creation-form-wrapper {
    background: #fff;
    border-radius: 12px;
    padding: 20px 30px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .jd-creation-form-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 30px;            
        }
        .jd-creation-edit-button {
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            border: none;
        }

    }

    .project-type-option-wrapper {

        .project-option {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .jd-creation-form-divider {
        background: #008fff;
        margin: 15px 0 30px;
    }

    .jd-creation-form {

        .jd-creation-field-item { 
            label {
                font-size: 12px;
                font-weight: 500;
            }
            
            .jd-creation-input-large {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 75px;                
                border: none;
                background: #e6f4ff;
            }
            .jd-creation-input {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 25px;
                background: #e6f4ff;
            }
            .jd-creation-number {
                text-align: left;
                height: 40px;
                width: 200px;
                background: #edf1f4;
            }
        }
        .jd-creation-radio-wrapper {
            background: #e6f4ff;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 35px;
            padding: 10px 20px;
        }

        .dual-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        .triple-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
        }
    }

}
