.candidateAddModel-title {
    text-align: center;
}
.candidateAddModel-input {
    text-align: left;
    height: 30px;
}

.role {
    font-size: small;
    font-weight: 500;
  }