.teamTask-wrapper {
    margin-top: 40px;
    padding: 0 20px;

    .teamTask-title {
        font-size: 10px;
        font-weight: 500;
    }

    .teamTask-filter {
        display: flex;
        flex-direction: row-reverse;
        gap: 2px;
        justify-content: end;
        .teamTask-filter-item-wrapper{
            display: flex;
            flex-direction: column;
            .teamTask-filter-item-label{
                font-size: 10px;
            }
            .teamTask-filter-item{
                font-size: 10px;
            }
        }
    }
    .teamTask-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .jd-create-button {
            background-color: #008fff;
            height: 40px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .jd-grid-wrapper {
        margin-top: 30px;

        .jds-title-grid-wrapper {
            display: grid;
            grid-template-columns: repeat(16, 1fr);
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: #008bf7;

            .jds-grid-title {
                height: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                text-align: center;

                &:nth-child(odd) {
                    box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
                }
                &:first-child {
                    border-top-left-radius: 12px;
                }
                &:last-child {
                    border-top-right-radius: 12px;
                }
            }
        }
        .jds-info-grid-wrapper {
            display: grid;
            grid-template-columns: repeat(16, 1fr);

            &:last-child {
                .jd-info {
                    &:first-child {
                        border-bottom-left-radius: 12px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 12px;
                    }
                }
            }

            .jd-info {
                padding: 10px;
                text-align: center;
                background-color: #e6f4ff;
                min-height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(odd) {
                    background-color: #b8e0ff;
                }
            }
        }
    }
}
