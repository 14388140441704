.jobListing-wrapper{
    display: flex;
    flex-direction: column;
    gap:10px;

    .jobListing-header{
        padding: 20px;
        display: flex;
        flex-direction: row; 
        height: 80px;
        width: auto;
        font-size: 12px;
        font-weight: 550;
        justify-content: space-around;
        gap: 50px;
        background-color:#c2d1f0;
        align-items: center;
  
  
        .jobListing-header-logo{
          align-items: center;
          padding: 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          .jobListing-logo-img{
              height: 40px;
              width: 40px;
              padding: 5px;
          }
          .header-logo-name{
             font-size: 14px;
          }
      }
        .header-menu{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          gap:100px; 
        }
        .header-sign{
          display: flex;
          flex-direction: row;  
          margin-top: 10px;
          gap: 5px;
  
          .sign-button{
              font-size: 12px;
              font-weight: 550;
          }
          .job-button{
              font-size: 12px;
              font-weight: 550;
          }
        }

      }    
    .jobListing-title{
        padding:5px;
        display: flex;
        flex-direction: column;
        gap:20px;
        background-color:#c2d1f0;
        .jobListing-punchLine{
          margin-top: 30px;
          align-self: center;
          text-align: center;
          width: 30%;
          font-size: 30px;
          padding:5px;
        }
        .jobListing-puncLineSmall{
          align-self: center;
          text-align: center;
          width: 40%;
          font-size: 16px;  
          font-weight: 500px;        
          padding:5px;
        }       
        .jobListing-search-wrapper{

          justify-items: center;
          padding: 5px;
          width: auto;

          background-color: rgb(240, 241, 240);
          border-radius: 30px;

          font-size: 12px;
          text-align: left;
          margin-bottom: 10px;

          .jobListing-search{
            display: grid;
            grid-template: 80px / auto auto auto;
            gap: 2px;
            align-self: center;
            align-items: center;
            margin-top: 20px;

            .jobListing-search-item{
              text-align: left;
              width: 150px;
              height: 35px;
            }
          }
          .jobListing-search-button{
            height: 30px;
            width: 175px;
            font-weight: 600;
            border-radius: 20px;
          }
        }

    }
    .jobListing-category{
        background-color: red;
        padding:5px;
        height: 500px;
        border-radius: 90px;
        margin-left: 80%;        
    }
    .jobListing-feature-heading{
      align-self: center;
      font-size: 14px;
      font-weight: 600;
      color: rgb(45, 46, 52);
      margin-top: 20px;
    }
    .jobListing-feature-subheading{
      align-self: center;
      font-size: 14px;
      font-weight: 700;
      color: rgb(9, 244, 165);
      margin-top: 2px;
    }    
    .jobListing-feature-card-wrapper{
      background-color: rgb(248, 245, 245);
      display: grid;
      padding: 20px;
      gap: 10px;
      justify-content: center;
      justify-self: center;
      align-self: center;
      grid-template: 80px 80px / 250px 250px 250px 250px;
  
      .jobListing-feature-card{
        padding: 1px;
      }
    }    
}