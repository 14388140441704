.Addjd{
    background-color: rgba(0, 255, 255, 0.05);
    border-radius: 33px 6px 36px 6px;
    margin-bottom: 9px;
    padding: 0px 15px;
}
.add-icon{
    height: 23px
}
.mainDiv{
    overflow: scroll;
}