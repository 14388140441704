.resumeResultSearchSummary-wrapper {

    display: flex;
    margin:10px;
    padding:10px;
    gap:10px;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    .resumeResultsSearchSummary-title{
        padding:3px;
        font-size: 10px;
        font-weight: 500;
        text-align: center;        
    }
    .resumeResultsSearchSummary-details{
        padding:3px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;        
    }    
}
