.teamAssignment-wrapper {
    margin-top: 40px;
    padding: 0 20px;

    .teamAssignmentTitle{
       font-size: 12px;
       font-weight: 600;
       margin-top:15px;
    }
}
