body {
    background: #eee;
  }
  
  .bgWhite {
    background: white;
    box-shadow: 0.1px 0.1px 0.1px 0.1px #cacaca;
  }
  
  .title {
    margin-top: 20px;
    font-size: 15px;
    
  }
  .otp{
    margin-left: 3px;
  }
  
  .customBtn {
    color: #fff;
    background-color: #00a1ff !important;
    border-color: #00a1ff !important;
    width: 100%;
    padding: 12px 20px !important;
    border-radius: 3px !important;
    /* border: 1px solid #9b9b9b; */
    font-weight: 400 !important;
    font-size: 0.925rem !important;
    display: inline-block;
  }
  
  form input {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  
  .avatar{
    text-align: center;
    height: 60px;
    width: 60px;
    background-color: orange;
    border-radius: 50%;
    margin-left: 125px;
    margin-bottom:10px;
  }
  .card-container{
    margin: 60px auto 40px auto;
    padding: 40px;
    /* width: 360px; */
   background: white;
    box-shadow: 1px 1px 4px ;
    border-radius: 5px;
    border: 2px solid #eee;
    animation: swing-in .15s;
  }
  p{
    opacity: 0.7;
  }
  .resendButton{
    
    background-color: white !important;
    border: none !important;
    padding-bottom: 15px !important;

  }
  .center12{
    justify-content: center;
  }