.activity-summary-wrapper {
    background: #cce9ff;
    padding: 10px;
    border-radius: 12px;

    .activity-summary-title {
        font-size: 16px;
        font-weight: 600;
    }

    .activity-wrapper {
        margin-top: 30px;
        gap: 15px;
        width: 50%;
        .activity {
            display: flex;
            justify-content: space-between;
            gap: 30px;

            .activity-name {
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding-left: 20px;
                font-weight: 500;
                background: #ffffff;
                flex-grow: 1;
            }

            .activity-button {
                background: #008fff;
                height: 44px;
                padding: 0 25px;
                font-size: 16px;
                font-weight: 600;
            }
            .activity-bar-stackChart-wrapper {
                margin-left: 1px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;

                .activity-bar-stackChart-wrapper-container {
                    width: 300px;
                    height: 300px;
                }
            }             
        }         
    }  
    
}
