.footer-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .footer-header{
      padding: 5px;
      display: flex;
      flex-direction: row; 
      height: 80px;
      width: auto;
      font-size: 12px;
      font-weight: 500;
      align-self: center;

      .footer-logo{
        align-items: center;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .footer-logo-img{
            height: 120px;
            width: 120px;
        }
        .footer-logo-name{
           font-size: 14px;
        }
    }
      .footer-menu{
        display: flex;
        flex-direction: row;
        align-self: center;
      }
    }
}