.resumeAnalysis-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px;

  .resumeAnalysis-header{
      padding: 20px;
      display: flex;
      flex-direction: row; 
      height: 80px;
      width: auto;
      font-size: 12px;
      font-weight: 550;
      justify-content: space-between;
      gap: 50px;
      background-color:rgb(236, 223, 223);
      align-items: center;


      .header-logo{
        align-items: center;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .header-logo-img{
            margin-top: 30px;
            height: 120px;
            width: 120px;
        }
        .header-logo-name{
           font-size: 14px;
        }
    }
      .header-menu{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap:100px; 
        list-style-type: none;
      }
      .header-sign{
        display: flex;
        flex-direction: row;  
        margin-top: 10px;
        gap: 5px;

        .sign-button{
            font-size: 12px;
            font-weight: 550;
        }
        .job-button{
            font-size: 12px;
            font-weight: 550;
        }
      }

    }    
  .jobListing-title{
      padding:5px;
      display: flex;
      flex-direction: column;
      gap:20px;
      justify-content: center;

      .jobListing-punchLine{
        margin-top: 30px;
        align-self: center;
        text-align: center;
        width: 30%;
        font-size: 30px;
        padding:5px;
      }
      .jobListing-puncLineSmall{
        align-self: center;
        text-align: center;
        width: 40%;
        font-size: 16px;  
        font-weight: 500px;        
        padding:5px;
      }       
      .resumeAnalysis-search-wrapper{
        align-self: center;
        align-items:center;
        padding: 5px;
        width: 40%;

        background-color: rgb(240, 241, 240);
        border-radius: 30px;

        font-size: 12px;
        text-align: left;
        justify-items: center;
        .resumeAnalysis-heading{
          margin-top: 15px;
          text-align: center;
          font-size: 12px;
        }
        .resumeAnalysis-search{
          display: grid;
          padding: 10px;
          padding-top: 1px;
          grid-template: 60px 40px / auto auto;
          gap: 10px;
          align-self: center;
          align-items: center;

          .resumeAnalysis-search-item{
            text-align: left;
            width: 280px;
            height: 35px;
          }
        }
        .resumeAnalysis-search-item-keywords{
          padding-left: 10px;
          text-align: left;
          width: 350px;
          height: 35px;
        }
        .resumeAnalysis-search-button{
          justify-self: center;
          align-self: center;
          align-items: center;
          justify-content: center;
          align-content: center;
          justify-items: center;
  
          height: 30px;
          width: 150px;
          font-weight: 600;
          border-radius: 20px;
          margin: 10px
        }        
      }

  }
  .jobListing-category{
      background-color: red;
      padding:5px;
      height: 500px;
      border-radius: 90px;
      margin-left: 80%;        
 
  } 

}
