.resumeAnalysisNew-wrapper {
    margin-top: 40px;
    padding: 20px;
  
     background-color: rgb(240, 243, 245);
     .resumeAnalysisNew-heading-filename{
      margin: 5px;
      text-align: center;
    }
    .resumeAnalysisNew-heading{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 10px 10px;
      font-size: small;
      justify-content: center;
      color: rgb(99, 98, 98);
      .resumeAnalysisNew-heading-items{
        margin-top: 1px;
      }
    }
    .resumeAnalysisNew-verdicts{
      margin-top: 20px;
      display: flex;
      flex-direction: column ;
      .resumeAnalysisNew-verdicts-final{
        padding:1px
      }
      .resumeAnalysisNew-verdicts-skills{
        margin-top:20px;
        padding:1px
      }
      .resumeAnalysisNew-verdicts-others{
        margin-top: 55px;
        display: grid ;
        grid-template-columns: auto auto auto;
        gap: 20px 10px;
        .resumeAnalysisNew-verdicts-others-items{
          margin-top: 1px;
        }
      }
  
    }
  
  }
  
  