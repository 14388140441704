.header-image-wrapper{
    padding:5px;
    
    display: flex;
    flex-direction: column;
    align-self: center;

    .header-image{
        align-self: center;
        height: 70px;
        width:70px;
        cursor: pointer;
    }
}
