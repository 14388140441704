.features-wrapper{
    display: flex;
    flex-direction: column;
    gap:20px;

    .feature-heading{
      align-self: center;
      font-size: 30px;
      font-weight: 600;
      color: #3b3c40;
      margin-top: 20px;
    }
    .feature-subheading{
      align-self: center;
      font-size: 20px;
      font-weight: 700;
      color: #555758;
      margin-top: 20px;
    }
    .feature-contents{
      align-self: center;
      padding:25px;
      font-size: 14px;
      font-weight: 500;
      color: #464948;
      margin-top: 2px;
    }
    .feature-card-wrapper{
      background-color: rgb(248, 245, 245);
      display: grid;
      padding: 50px;
      gap: 20px;
      grid-template: 140px 140px / auto auto auto;

      .feature-card{
        background-color: pink;
      }
    }

    .feature-carousil-wrapper{
      display:block;
      
      .feature-carousil{
        display:block
        .feature-carousil-item{
            height: 160px;
            width: auto;
            background: gray
       }
      }
      * { min-height: 0; min-width: 0; } 
  
    } 
    .feature-img-wrapper{
      background-color: rgb(255, 255, 255);
      display: grid;
      padding: 5px;
      gap: 20px;
      grid-template: 500px / auto auto;

      .feature-card{
        background-color: rgb(182, 241, 184);
        font-size: 30px;
        font-weight: 700;
        align-self: center;
        padding:10px;
      }
    } 
    .feature-img2-wrapper{
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: row;
      padding: 5px;
      gap: 20px;
      justify-content: center;

      .feature-card{
        background-color: rgb(182, 241, 184);
        font-size: 30px;
        font-weight: 700;
        align-self: center;
        padding:10px;
      }
    }    
    .feature-endHeading-wrapper{
 
      display: flex;
      flex-direction: row;
      gap: 20px;

      margin-top: 80px;
      margin-bottom: 15px;
      .feature-endHeading{
        align-self: left;
        font-size: 60px;
        font-weight: lighter;
        font-family:'Raleway';
        margin: 10px;
        margin-left: 100px;
      }
      .feature-Button-wrapper{
        align-self: center;
        justify-self: end;
        margin-left: 50px;
        .feature-Button{
          height: 50px;
          width: 150px;
          font-size: 20px;
          font-family:'Raleway';
          background-color: rgb(90, 113, 244);
          color: whitesmoke;
          font-weight: lighter;
          padding: 5px;
        }
      }
    }

 
}