.sidebar-wrapper {
    // background: #cce9ff;
    padding: 10px 5px;
    margin-top: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    flex-shrink: 5px;
    gap: 5px;

    .sidebar-link {
        transition: all 0.2s ease;
        text-decoration: none;
        display: block;
        // color: #000;
        font-size: 11px;
        font-weight: 500;
       // background-color: #fff;
        border-radius: 12px;
        padding: 4px;
        //box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.2);

        &:hover {
            color: #008fff;
        }
    }
}
.rotate {
    transform: rotate(90deg); /* or the desired rotation value */
  }
  .listShow li{
   color :#eef4f8
  }

  .sideFont{
    
    font-family: Raleway;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    text-decoration: none;
    color: black;
  }