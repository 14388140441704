.subUserAddModel-title {
    text-align: center;
}
.subUserAddModel-input {
    text-align: left;
    height: 30px;
}

.role {
    font-size: small;
    font-weight: 500;
    text-align: left;
  }