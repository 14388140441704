.otpprocess-wrapper{
display: flex;
flex-direction: column;
gap: 10px;
.otpprocess-form-wrapper {
  background: #fff;
  border-radius: 12px;
  margin-top: 30px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;


  .signupnew-leftarea{
    align-items: center;
    align-self:center;
     padding: 5px;
     justify-content: center;
  }
  .signupnew-rightarea{
      padding: 5px;
      .signupnew-heading{
          padding: 10px;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          margin: 20px;
      }
      .profile-form {
          padding: 20px;
          .profile-field-item { 
              label {
                  font-size: 12px;
                  font-weight: 500;
              }
              
              .profile-input-large {
                  text-align: left;
                  font-size: 12px;
                  font-weight: 400;
                  height: 75px;                
                  border: none;
                  background: #e6f4ff;
              }
              .profile-input {
                  text-align: left;
                  font-size: 12px;
                  font-weight: 400;
                  height: 30px;
                  background: #e6f4ff;
                  width: 50%;
              }
              .profile-input-full {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 30px;
                background: #e6f4ff;
            }
              .profile-number {
                  text-align: left;
                  height: 40px;
                  width: 200px;
                  background: #e6f4ff;
              }
          }
          .profile-radio-wrapper {
              background: #e6f4ff;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              align-items: center;
              border-radius: 8px;
              height: 50px;
              font-size: 12px;
              font-weight: 400;
              padding: 10px 20px;
          }
  
          .radio-option {
              font-size: 12px;
              font-weight: 400;
          }        
          .signinnew-dual-input-wrapper {
              display: grid;
              grid-template-columns: 400px 400px;
          }
          .triple-input-wrapper {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 30px;
          }
      } ;
      .signupnew-links-wrapper{
          display: flex;
          flex-direction: row;
          gap:10px;
          justify-content: space-between;
          margin-top: 20px;
          .signupnew-links{
              margin: 5px;
              font-size: 12px;
              font-weight: 500px;
          }

      }       
  }
}
.otpprocess-footer{
  text-align: center;
  margin: 0 auto;
  margin-top: 120px;
  padding: 0 20px;
  font-size: 12px;
}
}