.resumeDB-list-wrapper {
    background-color: #fff;
    padding: 30px 20px 1px;
    margin: 40px 20px;
    border-radius: 12px;
    .resumeDB-title{
        text-align: center;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .candidate-list-btns-wrapper {
        display: flex;
        gap: 20px;
        justify-content: center;

        .candidate-list-btn {
            min-width: 140px;
            border-radius: 40px;
            border: none;
            background-color: #fff;
            color: #008fff;
            font-weight: 600;
            height: 35px;
        }

        .search-btn {
            min-width: 140px;
            border-radius: 40px;
            border: none;
            font-weight: 600;
            height: 35px;
        }
    }

    .candidate-list-wrapper {
        background-color: #fff;
        padding: 1px 20px 20px;
        border-radius: 12px;
    }
    .jd-creation-form {

        .jd-creation-field-item { 
            label {
                font-size: 12px;
                font-weight: 500;
            }
            
            .jd-creation-input-large {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 75px;                
                border: none;
                background: #fff;
            }
            .jd-creation-input {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 25px;
                background: #fff;
                margin: 3px;
                width: 80%
            }
            .jd-creation-number {
                text-align: left;
                height: 40px;
                width: 200px;
                background: #fff;
            }
        }
        .jd-creation-radio-wrapper {
            background: #fff;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 35px;
            padding: 10px 20px;
        }
        .jd-creation-radio-wrapper-3 {
            background: #fff;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 40px;
            padding: 1px 20px;
        }
        .jd-creation-radio-wrapper-4 {
            background: #fff;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 80px;
            padding: 1px 20px;
        }        
        .dual-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        .triple-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
        }
        .four-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 30px;
        }
    }    
    .resumeDb-submit-button{
        display: flex;
        height: 40px;
        font-Weight:600;
        justify-content: center;
        align-items: center;
        align-self: center;

    }
}
