.searchResultsFilter-wrapper{
    display: flex;
    flex-direction: column;
    gap:5px;

    .searchResultsFilter-field-item { 
      display: flex;
      flex-direction: row;
      gap:5px;

      label {
          font-size: 14px;
          font-weight: 600;
      }
      .searchResultsFilter-input {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        width: 80px;
        height: 30px;
        background: #ffffff;
        margin: 10px;
        margin-top:2px;
    }
    .searchResultsFilter-radio-wrapper{
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      .radio-option{
        text-align: left;
        font-size: 12px;
        font-weight: 400;
      }
    }

     }
         

}