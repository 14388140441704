.careerListing-wrapper{
    display: flex;
    flex-direction: column;
    gap:10px;

    .careerListing-header{
        padding: 20px;
        display: flex;
        flex-direction: row; 
        height: 80px;
        width: auto;
        font-size: 12px;
        font-weight: 550;
        justify-content: space-between;
        gap: 50px;
        background-color:rgb(241, 244, 235);
        align-items: center;
  
  
        .header-logo{
          align-items: center;
          padding: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .header-logo-img{
              margin-top: 30px;
              height: 120px;
              width: 120px;
          }
          .header-logo-name{
             font-size: 14px;
          }
      }
        .header-menu{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          gap:100px; 
        }
        .header-sign{
          display: flex;
          flex-direction: row;  
          margin-top: 10px;
          gap: 5px;
  
          .sign-button{
              font-size: 12px;
              font-weight: 550;
          }
          .job-button{
              font-size: 12px;
              font-weight: 550;
          }
        }
        
      }    
    .careerListing-title{
        padding:5px;
        display: flex;
        flex-direction: column;
        gap:20px;

        .careerListing-punchLine{
          margin-top: 30px;
          align-self: center;
          text-align: center;
          width: 60%;
          font-size: 30px;
          padding:5px;
        }
        .jobListing-puncLineSmall{
          align-self: center;
          text-align: center;
          width: 40%;
          font-size: 16px;  
          font-weight: 500px;        
          padding:5px;
        }       
        .jobListing-search-wrapper{
          align-self: center;
          align-items:center;
          padding: 5px;
          width: auto;

          background-color: rgb(240, 241, 240);
          border-radius: 30px;

          font-size: 12px;
          text-align: left;

          .jobListing-search{
            display: grid;
            grid-template: 80px / auto auto auto;
            gap: 2px;
            align-self: center;
            align-items: center;
            margin-top: 20px;

            .jobListing-search-item{
              text-align: left;
              width: 270px;
            }
            .jobListing-search-button{
              height: 40px;
              width: 200px;
              font-weight: 600;
              border-radius: 20px;
            }
          }
        }
        .company-main-image{
          height: 120px;
          width: 120px;
        }
    }
    .careerListingCompanyList-wrapper{
      display: flex;
      flex-direction: column;
      gap:20px;
  
      .feature-heading{
        align-self: center;
        font-size: 30px;
        font-weight: 600;
        color: rgb(45, 46, 52);
        margin-top: 20px;
      }
      .feature-subheading{
        align-self: center;
        font-size: 14px;
        font-weight: 600;
        color: rgb(9, 244, 165);
        margin-top: 2px;
      }
      .feature-card-wrapper{
        background-color: rgb(248, 245, 245);
        display: grid;
        padding: 50px;
        gap: 20px;
        grid-template: 250px 250px / auto auto auto;
  
        .company-card{
          padding:1px;
          display: flex;
          flex-direction: column;

          .company.website{
            padding:1px;
          }
          .company.logo{
            padding:1px;
            height: 50px;
            width: 50px;
          }          
        }
      }
  
  }   
}