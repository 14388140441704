.profile-form-wrapper {
    background: #fff;
    border-radius: 12px;
    padding: 20px 30px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile-form-title {
            margin: 12px;
            font-size: 14px;
            font-weight: 600;
            color: #008fff;
        }
        .profile-edit-button {
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            border: none;
        }

    }

    .project-type-option-wrapper {

        .project-option {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .profile-form {

        .profile-field-item { 
            label {
                font-size: 12px;
                font-weight: 500;
            }
            
            .profile-input-large {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 75px;                
                border: none;
                background: #e6f4ff;
            }
            .profile-input {
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                height: 30px;
                background: #e6f4ff;
                width: 50%;
            }
            .profile-number {
                text-align: left;
                height: 40px;
                width: 200px;
                background: #e6f4ff;
            }
        }
        .profile-radio-wrapper {
            background: #e6f4ff;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 50px;
            font-size: 12px;
            font-weight: 400;
            padding: 10px 20px;
        }

        .radio-option {
            font-size: 12px;
            font-weight: 400;
        }        
        .dual-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        .triple-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
        }
    }

}
