.jd-summary-wrapper {
    background: #fff;
    border-radius: 12px;
    padding: 20px 30px;

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 30px;  
        }

        .btn {
            min-width: 180px;
            background: none;
            border: none;
            font-weight: 600;
            font-size: 12px;
            height: 50px;
            color: black;
        }
        .image{
            margin-right: 10px;
            padding-bottom: 2px;
        }
        
    }

    .jd-summary-buttons-wrapper {
        display: flex;
        gap: 0.5px;

        .jd-summary-btn {
            min-width: 110px;
            color: #008fff;
            font-weight: 600;
            font-size: 10px;
            height: 25px;
            border: 1px solid #008fff;
        }

        .jd-summary-btn-active {
            min-width: 110px;
            background: #008fff;
            color: #fff;
            font-weight: 600;
            font-size: 10px;
            border: none;

            &:hover {
                color: #fff;
                background: #0091ffda;
            }
        }
    }

    .chart-summary{
       display: flex;
       justify-content: space-between;
       width: 100px;
       height: 100px;
       margin-bottom: 200px;
       margin-right: 70px;
    }
}
