.resumeDBSearchResults-wrapper {

    display: flex;
    flex-direction: row;
    margin:10px;
    gap:10px;

    flex-wrap: wrap;

    .resumeDBSearchResults-Stats{
        width: 20%;
        background-color:  rgb(235, 235, 245);
        padding:3px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
    .resumeDBSearchResults-summary-search{
        width: 70%;
        padding:3px;
    }

    .resumeDBSearchResults-filters{
        width: 20%;
        background-color: white;
        padding:3px;
    }

    .resumeDBSearchResults-card-list{
        width: 70%;
        padding:3px;
    }


}
