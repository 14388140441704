.jd-assignment-form-wrapper {
    background: #fff;
    border-radius: 12px;
    padding: 20px 30px;

    .jd-assignment-form-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .project-type-option-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .project-option {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .jd-assignment-form-divider {
        background: #008fff;
        margin: 15px 0 30px;
    }

    .jd-assignment-form {
        .jd-assignment-field-item {
            label {
                font-weight: 600;
            }
            .jd-assignment-input {
                height: 35px;
                border: none;
                background: #e6f4ff;
            }
        }
        .jd-assignment-field-item-left {
            text-align: left;
            label {
                font-weight: 600;
            }
            .jd-assignment-input {
                height: 35px;
                border: none;
                background: #e6f4ff;
            }
        }

        .jd-assignment-radio-wrapper {
            background: #e6f4ff;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            border-radius: 8px;
            height: 35px;
            padding: 0 20px;
        }

        .dual-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        .triple-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
        }
        .double-input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
    }
}
