.rowdesign{
    /* height: 100%; */
    min-height: 92vh;
    /* background-color: #cce9ff; */
}
.border{
    border: 2px solid black;
}
.col-3{
    width: 10% !important;
    background-color: rgba(0, 143, 255, 0.1);
}
.col-9{
    width: 90% !important;
}
.bodyPart{
    padding: 0px !important;
}