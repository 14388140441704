.create-jd-wrapper {
    background: #cce9ff;
    border-radius: 12px;
    padding: 15px;
    margin: 40px 20px;

    .jd-primary-info-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .jd-primary-info {
            display: flex;
            align-items: center;
            gap: 20px;

            .job-id-tag {
                border-radius: 40px;
                height: 35px;               
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color:grey;
            }

            .jd-primary-btn {
                border-radius: 40px;
                // color: #008fff;
                min-width: 80px;
                height: 30px;
                font-weight: 600;
                font-size: 12px;
                border: none;
            }
        }
    }

    .jd-creation-form-options-wrapper {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .jd-creation-form-first-options-wrapper {
            display: flex;
            gap: 0px;
            align-items: center;
        }
        .jd-form-option-btn {
            border-radius: 1px;
            height: 30px;
            min-width: 80px;
            color: #000;
            background-color: #fff;
            
        }

        .jd-form-option-btn-active {
            background-color: #e8f0f1;
            font-weight: 600;
            font-size: 12px;
            border: 2px solid #fff;
        }
    }
}
