.customers-container {
    margin: 40px 20px;

    .customers-btns-wrapper {
        display: flex;
        gap: 2px;

        .customer-btn {
            font-size: 14px;
            font-weight: 600;
            min-width: 80px;
            height: 30px;
            border-radius: 1px;
            border: 1px solid #008fff;
            color: #008fff;
            padding: 0 30px;
        }

        .customer-btn-active {
            background: #008fff;
            color: #fff;
        }
    }

    .customer-members-wrapper {
        margin-top: 50px;

        .specific-customer-title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }
        .members-list-title {
            font-size: 28px;
            font-weight: 600;
        }

        .customer-members-add-wrapper {
            margin-right: 10px;
            margin-left: 35px;
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 0;

            .customer-members-add-btn {
                background: #008fff;
                font-size: 16px;
                height: 38px;
                min-width: 150px;
            }
        }
    }
}
