.login {
  margin: 60px auto 40px auto;
  padding: 40px;
  width: 360px;
  background: white;
  box-shadow: 0.1px 0.1px 0.1px 0.1px;
  border-radius: 5px;
  border: 2px solid #eee;
  animation: swing-in 0.15s;
}
.container1 {
  background-color: #f9f9f9;
  height: 100vh;
}
.header {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  text-align: center;
}
.header img {
  max-width: 60px;
}
.form-header {
  font-size: 24px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 20px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
.form-input {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.Submit_button {
  color: #fff;
  background-color: #00a1ff;
  border-color: #00a1ff;
  width: 100%;
  padding: 12px 20px;
  border-radius: 3px;
  /* border: 1px solid #9b9b9b; */
  font-weight: 400;
  font-size: 0.925rem;
  display: inline-block;
}

.footer {
  width: 360px;
  text-align: center;
  margin: 0 auto;
  padding: 0 20px;
}
.footer-logo {
  margin: 0px 12px;
  height: auto;
  width: 12px;
}
.footer p {
  color: #9b9b9b;
  font-size: 0.725rem;
  opacity: 0.7;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* Animated input */
div.input-block {
  position: relative;
}
div.input-block input {
  /* font-weight: 300; */
  /* font-size: 1.6rem; */
  color: #495055;
  width: 100%;
  text-align: left;
  padding: 15px 15px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  outline: none;
}
div.input-block span.placeholder {
  position: absolute;
  margin: 10px 0;
  padding: 0 4px;
  /* font-family: Roboto, sans-serif; */
  background-color: white;
  opacity: 100%;
  color: #6c757d;
  display: flex;
  align-items: center;
  font-size: 1rem;
  top: 0;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}
div.input-block input:valid + span.placeholder,
div.input-block input:focus + span.placeholder {
  transform: scale(0.8) translateY(-25px);
  background: white;
  z-index: 99;
}
/* div.input-block input:focus{
    color: #284B63;
    border-color: #284B63;
  } */
/* div.input-block input:focus + span.placeholder {
    color: #284B63;
  } */

.signup {
  margin: 60px auto 40px auto;
  padding: 40px;
  width: 67%;
  background: white;
  box-shadow: 0.1px 0.1px 0.1px 0.1px;
  border-radius: 5px;
  border: 2px solid #eee;
  animation: swing-in 0.15s;
}
.userType {
  opacity: 0.7;
}

.header-image-wrapper{
  padding:5px;
  
  display: flex;
  flex-direction: column;
  align-self: center;

  .header-image{
      align-self: center;
      height: 70px;
      width:70px;
      cursor: pointer;
  }
}