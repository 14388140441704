.teamAssignmentCard-wrapper {
    margin-top: 10px;

    display: flex;
    flex-direction: column; 
    gap:  2px;
    border: 0.1px solid #f2eeee;
    padding: 2px;
    background-color: rgb(249, 252, 248);

    .teamAssignmentCard-JD-title-wrapper{
        padding: 2px;
        font-size: 12px;
        font-weight: 600;
        border: 0.1px solid #f6f1f1;
        background-color: rgb(237, 238, 249);

        .param_wrapper_0{
            display: flex;
            flex-direction: row;
            
            .param_wrapper_0_0{
                font-size: 11px;
                font-weight: 500;
                padding:5px;
                width:max-content;
            }
            .param_wrapper_0_1{
                justify-content: flex-end;
                margin-left: auto;
                font-size: 10px;
                font-weight: 450;
                padding:5px
            }
        }
    }
    .teamAssignmentCard-Jd-assignment-wrapper{
        padding: 2px;
        display: flex;
        flex-direction: row;
        gap:  200px;
        border: 0.1px solid #f1ecec;
        justify-content: end;
        background-color: rgb(237, 238, 249);       
        .param_wrapper{
            display: flex;
            flex-direction: row;
            gap: 5px;
            .param_wrapper_1{
                font-size: 12px;
                font-weight: 600;
                background-color: rgb(239, 239, 165);
                padding:5px
            }
            .param_wrapper_2{
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
    .teamAssignmentCard-Stats-wrapper{
        display: flex;
        flex-direction: row;
        margin: 5px;
        
        .teamAssignmentCard-Stats-value-wrapper{
            padding: 2px;
            display: flex;
            flex-direction: column;
            font-size: 10px;
            font-weight: 600;

            .teamAssignmentCard-Stats-candidateStatus{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
                
            }        
            .teamAssignmentCard-Stats-resumeStatus{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
            }
            .teamAssignmentCard-Stats-interviewType{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
            }
            .teamAssignmentCard-Stats-interviewAction{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
            }            
            .teamAssignmentCard-Stats-interviewStatus{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
            }  
            .teamAssignmentCard-Stats-selectionStatus{
                padding: 2px;
                font-size: 10px;
                font-weight: 400;
            }  
        }
        .teamAssignmentCard-Stats-action-wrapper{
            padding: 2px;
        }        
            
    }

}
