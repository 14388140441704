.forWho-wrapper{
    display: flex;
    flex-direction: column;
    gap:10px;

    .forWho-heading{
      align-self: center;
      font-size: 25px;
      font-weight: 600;
      color: rgb(45, 46, 52);
      margin-top: 20px;
    }
    .forWho-subheading{
      align-self: center;
      font-size: 14px;
      font-weight: 600;
      color: rgb(9, 244, 165);
      margin-top: 2px;
    }
    .forWho-card-wrapper{
      background-color: rgb(246, 252, 245);
      display: grid;
      padding: 50px;
      gap: 20px;
      grid-template: 140px / 270px 270px 270px;

      .forWho-card{
        padding:1px;
      }
    }
    .forWho-stats-wrapper{
      display: flex;
      flex-direction: row;
      padding: 50px;
      gap: 100px;

      .forWho-stats{
        padding:1px;
      }
    }

}