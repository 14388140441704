.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content:left;
  align-items: center;
  // background: #ccfff1;
  background-color: rgba(0, 143, 255, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.25);

  .logo-wrapper {
    align-items: center;
    .logo {
      margin-left: 35px;
      margin-top: 20px;
      height:80px;
      width: 80px;
    }
  }

  .services-links-wrapper {
    padding-left: 40px;
    display: flex;
    width: 65%;

    gap: 50px;

    .links {
      text-decoration: none;
      color: #000;
      font-weight: 600;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        color: #008fff;
      }
    }
  }

  .info-wrapper {
    display: flex;
    width: 35%;

    gap: 10px;
    align-items: center;

    .info-logo {
      width: 22px;
      cursor: pointer;
    }
  }

  .user-avatar {
    background: #008fff;
    cursor: pointer;
  }
}

.btn-header {
  padding:8px;
  color:rgb(27, 16, 2);
  background-color: rgba(0, 143, 255, 0.4);
  font-size: 12px;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 600;
  border: none;
}

.btn-header:hover {background: #eee;}

.profile-wrapper{

  color:grey;
  width:200px;
  font-size: small;
  font-weight: 500;

  .e-mail {
    font-size: large;
    font-weight: 600;
  }
  
  .log-out {
    font-size: small;
    font-weight: 500;
  }
  
}

