.teamAssignmentCardStatsView-wrapper {
    margin: 2px;
    display: flex;
    flex-direction: column; 
    gap: 1px;
    border: 1px solid #c3c3c3;
    padding: 10px;
    background-color: rgb(233, 233, 244);

    .teamAssignmentCardStatsView-title-wrapper{
        display: flex;
        flex-direction: row; 
        gap:15px;

        .teamAssignmentCardStatsView-title-heading{
            padding: 2px;
            font-size: 10px;
            font-weight: 700;
            width: 80%;
        }
        .teamAssignmentCardStatsView-title-action{
            padding: 2px;
            background-color: rgba(4, 87, 176, 0.808);
            font-size: 10px;
            font-weight: 600;
            color: antiquewhite;
        }
    }

    .teamAssignmentCardStatsView-Values-wrapper{
        display: flex;
        flex-direction: row;
        background-color: rgb(255, 255, 255);
        margin:2px;
        gap:15px;
        font-size: 10px;
        .teamAssignmentCard-Stats-subheading-wrapper{
            padding: 2px;
            font-size: 10px;
            font-weight: 500; 
            background-color: rgb(255, 255, 255); 
            width:80%;  
        } 
        .teamAssignmentCard-Stats-subheading-value-wrapper{
            padding: 2px;
            font-size: 10px;
            font-weight: 500;      
        }        
    }

}
