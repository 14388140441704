.resumeNav {
  
  margin-top: 35px;
 
  
}
.resumeNav button {
  border-radius: 50px;
  margin-right: 20px;
  background-color: white;
  color: black;
  text-align: center;
  width: 150px;
}

.resumeContainer{
  display: flex;
  flex-direction: column;
}
