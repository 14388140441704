.resumeAnalysisNew-wrapper {
  margin-top: 40px;
  padding: 20px;

   background-color: rgb(240, 243, 245);

  .resumeAnalysisNew-Id{
    display: flex;
    margin-top: auto;
    justify-content: center;
  }

  .resumeAnalysisNew-details{
    display: grid;
    gap: 50px 100px;
    grid-template-columns: auto auto auto;
    margin-top: 20px;

    .resumeAnalysisNew-details-items{
      text-align: center;
    }

  }
  .resumeAnalysisNew-keywords{
    margin-top: 30px;
    display: flex;
    flex-direction: column ;
    gap: 10px;
    text-align: left;
    
    .resumeAnalysisNew-keywords-items{
      text-align: left;
      font-size: medium;font-weight: 500;
    }
    .resumeAnalysisNew-keywords-button{
      text-align: left;
      background-color: transparent;
      border: none;
      width:40px;
      height:40px;
      justify-content: end;
      align-self: end;
      
    }
  }
  .resumeAnalysisNew-verdicts{
    margin-top: 20px;
    display: flex;
    flex-direction: column ;
    .resumeAnalysisNew-verdicts-final{
      padding:1px
    }
    .resumeAnalysisNew-verdicts-skills{
      margin-top:20px;
      padding:1px
    }
    .resumeAnalysisNew-verdicts-others{
      margin-top: 55px;
      display: grid ;
      grid-template-columns: auto auto auto;
      gap: 20px 10px;
      .resumeAnalysisNew-verdicts-others-items{
        margin-top: 1px;
      }
    }

  }

}

