.jobApplication-summary-wrapper{
    width: 80%;
    justify-self: center;
    .jobApplication-header-wrapper{
        padding: 2px;
        height: 200px;
        background-color: rgb(213, 227, 245);
        display: flex;
        flex-direction: column;
        gap: 5px;
        .jobApplication-heading { 
            text-align: center;
            padding:15px;
            font-size: 16px;
            font-weight: 500;
            background-color: rgb(179, 207, 243);
        } 
        .jobApplication-heading-role-desc{
            margin-top:30px;
            text-align:center;
            font-size: 12px;
            font-weight: 800;
        } 
        .jobApplication-heading-role{
            text-align:center;
            font-size: 16px;
            font-weight: 500;
        } 
        .jobApplication-Qh-branding {
            margin-top:30px;
            text-align:center;
            font-size: 12px;
            font-weight: 500;
        } 
    }

    .jobApplication-section-header {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 450;
        color: grey;
        margin-bottom: 20px; 
    }        
    .profile-creation-form-wrapper {
        background: #fff;
        border-radius: 12px;
        padding: 20px 30px;

        .header {

            margin-bottom: 30px;  
            margin-top: 10px;
            .jd-creation-form-suitability {
                display: flex;
                font-size: 16px;
                font-weight: 700; 
                text-align: center; 
                justify-content: center;       
            }
            .jd-creation-form-title {
                display: flex;
                font-size: 12px;
                font-weight: 400; 
                text-align: center; 
                justify-content: center;       
            }
        }

        .profile-creation-edit-button {
            display: flex;
            margin-left: auto;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            border: none;
        }
        .project-type-option-wrapper {

            .project-option {
                font-size: 14px;
                font-weight: 500;
            }
        }

        .jd-creation-form-divider {
            background: #008fff;
            margin: 15px 0 30px;
        }

        .profile-creation-form {

            .jd-creation-field-item { 
                label {
                    font-size: 12px;
                    font-weight: 500;
                }
                
                .jd-creation-input-large {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 400;
                    height: 75px;                
                    border: none;
                    background: #e6f4ff;
                }
                .jd-creation-input {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 400;
                    height: 40px;
                    background: #e6f4ff;
                }
                .jd-creation-number {
                    text-align: left;
                    height: 40px;
                    width: 200px;
                    background: #e6f4ff;
                }
            }
            .jd-creation-radio-wrapper {
                background: #e6f4ff;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                border-radius: 8px;
                height: 35px;
                padding: 10px 20px;
            }
            .jd-creation-radio-wrapper-3 {
                background: #e6f4ff;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                border-radius: 8px;
                height: 40px;
                padding: 1px 20px;
            }
            .jd-creation-radio-wrapper-4 {
                background: #e6f4ff;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                border-radius: 8px;
                height: 80px;
                padding: 1px 20px;
            }        
            .dual-input-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
            }
            .triple-input-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 30px;
            }
            .four-input-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 30px;
            }
        }
    }
    .jobApplication-footer{
        margin:20px;
        font-size: 12px;
        font-weight: 00; 
        text-align: center; 
        justify-content: center;           
    }
}
