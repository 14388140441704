.breadcrumbs{   
    background-color: rgba(0, 143, 255, 0.1);
}
.breadcrumbs > *{
    display: inline-block;
    margin-right: 0px;
}
.breadcrumbs .crumb:last-child::after{
    display: none;
}
.breadcrumbs .crumb a{
    color: black;
    font-size: 13 px;
    word-wrap: 'break-word';
    text-decoration: none;

}
.crumb span{
    margin-right: 4px;
    font-weight: 600;
    color: black;
    font-size: 13px;
}
.activeLink span{
    color: red !important;
    
}
.homeimg{
    height: 13px;
    width: 13px;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 1px;
}