.resumeAddModel-title {
    text-align: center;
}
.resumeAddModel-input {
    text-align: left;
    height: 30px;
}

.resumeAddModel.Role {
    font-size: small;
    font-weight: 500;
  }
