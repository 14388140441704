.maintopdiv{
    background-color: aqua;
    position: relative;
    height: 117px;
}
.firstPart{
    
}
.jd-container {
    display: flex;
    flex-direction: column;
    /* Add any additional styling you need for the container */
  }
.secondPart{
position: absolute;
bottom: -16px;
}
.secondPart button{
    border-radius: 50px;
    margin-right: 20px;
    background-color: white;
    color: black;
    text-align: center;
    width: 130px;
}
.firstButton{
    margin-left:20px ;
}
.mainbottomdiv{
    background-color: azure;
    
}
.table1{
    margin-left: 15%;
    background-color: aqua;
    
}
