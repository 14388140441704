.qhrflowvendorconnect-wrapper{
    display: flex;
    flex-direction: column;
    gap:2px;
   
    .qhrflowlanding-header{
      padding: 20px;
      display: flex;
      flex-direction: row; 
      height: 80px;
      width: auto;
      font-size: 12px;
      font-weight: 550;
      justify-content: space-evenly;
      gap: 50px;
      background-color:white;
      align-items: center;

    .qhrflowlanding-header-left {
      display: flex;
      flex-direction: row;
      gap: 50px;
      align-items: center;
    }
      .qhrflowlanding-logo{
        align-items: center;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .qhrflowlanding-logo-img{
            margin-top: 30px;
            height: 120px;
            width: 120px;
        }
        .qhrflowlanding-logo-name{
           font-size: 14px;
        }
    }
      .qhrflowlanding-menu{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        gap:55px; 
        list-style-type: none;
      }
      .qhrflowlanding-sign{
        display: flex;
        flex-direction: row;  
        margin-top: 20px;
        gap: 5px;

        .qhrflowlanding-sign-button{
            font-size: 12px;
            font-weight: 550;
        }
      }
    }

    .qhrflowlanding-main{
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap:60px;
      height: 80vh;
      background-color: rgb(240, 243, 248);
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      margin-top: 10px;
      .qhrflowlanding-main-text{
         font-size: 30px;
         font-weight: 700;
         text-align: center;
         margin-top: 20px;
         
      }
      .qhrflowlanding-main-slider{
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap:60px;
        justify-content: center;
        .qhrflowlanding-main-image{
          align-self: center;
          margin-top: 30px;
          height: 400px;
          width: 500px;
      }
        .qhrflowlanding-selectable-wrapper{
          width:40%;
          align-self:center;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          
          .qhrflowlanding-register-button{
            margin-top: 20px;
            font-size: 12px;
            font-weight: 550;
            align-self: center;
        }
        }
      }
    }
    .qhrflowvendorconnect-features{

      padding:100px;
      border: solid;
      border-width: 1px;
      border-color: rgb(229, 231, 235);
      width: 50%;
      .features-center{
        height: 500px;
        width: 50%;
        margin-top: 20px;
      }

   }
}

.header-left-link {
  text-decoration: none;
}
