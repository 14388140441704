.landing-wrapper{
    display: flex;
    flex-direction: column;
    gap:2px;
   
    .landing-header{
      padding: 20px;
      display: flex;
      flex-direction: row; 
      height: 80px;
      width: auto;
      font-size: 12px;
      font-weight: 550;
      justify-content: space-between;
      gap: 50px;
      background-color:white;
      align-items: center;

    .landing-header-left {
      display: flex;
      flex-direction: row;
      gap: 50px;
      align-items: center;
    }


      .header-logo{
        align-items: center;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .header-logo-img{
            margin-top: 30px;
            height: 120px;
            width: 120px;
        }
        .header-logo-name{
           font-size: 14px;
        }
    }
      .header-menu{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap:55px; 
        list-style-type: none;
      }
      .header-sign{
        display: flex;
        flex-direction: row;  
        margin-top: 3px;
        gap: 5px;

        .sign-button{
            font-size: 12px;
            font-weight: 550;
        }
        .job-button{
            font-size: 12px;
            font-weight: 550;
        }
      }
    }
}

.header-left-link {
  text-decoration: none;
}
