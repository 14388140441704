.notifications-wrapper {
    margin: 20px 50px;
    background: #cce9ff67;
    padding: 10px 30px;
    border-radius: 10px;
}

.user-avatar {
    background: #008fff;
    cursor: pointer;
}

.notifications-header{
    margin: 20px 60px;
}