.jobListing-wrapper{
    display: flex;
    flex-direction: column;
    gap:10px;

    .jobListing-header-results{
        padding: 20px;
        display: flex;
        flex-direction: row; 
        height: 80px;
        width: auto;
        font-size: 12px;
        font-weight: 550;
        justify-content: space-between;
        gap: 50px;
        background-color:rgb(236, 223, 223);
        align-items: center;
  
        .header-logo{
          align-items: center;
          padding: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .header-logo-img{
              margin-top: 30px;
              height: 120px;
              width: 120px;
          }
          .header-logo-name{
             font-size: 14px;
          }
      }
        .header-menu{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          gap:100px; 
        }
        .header-sign{
          display: flex;
          flex-direction: row;  
          margin-top: 10px;
          gap: 5px;
  
          .sign-button{
              font-size: 12px;
              font-weight: 550;
          }
          .job-button{
              font-size: 12px;
              font-weight: 550;
          }
        }
        
      }    
    .jobListing-title{
        padding:5px;
        display: flex;
        flex-direction: column;
        gap:20px;

        .jobListing-punchLine{
          margin-top: 30px;
          align-self: center;
          text-align: center;
          width: 30%;
          font-size: 30px;
          padding:5px;
        }
        .jobListing-puncLineSmall{
          align-self: center;
          text-align: center;
          width: 40%;
          font-size: 16px;  
          font-weight: 500px;        
          padding:5px;
        }       
        .jobListing-search-wrapper{
          align-self: center;
          align-items:center;
          padding: 5px;
          width: auto;

          background-color: rgb(240, 241, 240);
          border-radius: 30px;

          font-size: 12px;
          text-align: left;

          .jobListing-search{
            display: grid;
            grid-template: 80px / auto auto auto;
            gap: 2px;
            align-self: center;
            align-items: center;
            margin-top: 20px;

            .jobListing-search-item{
              text-align: left;
              width: 270px;
            }
            .jobListing-search-button{
              height: 40px;
              width: 200px;
              font-weight: 600;
              border-radius: 20px;
            }
          }
        }

    }
    .jobListing-Results{

      margin-left: 15px;
      display:flex;
      flex-direction: row;
      padding:5px;
      gap:20px;

      .jobListing-filters{
        padding-left:15px;
        padding-right:15px;
        padding: 15px;
        width: 20%;
        background-color: rgb(238, 238, 246);
        display: flex;
        flex-direction: column;

        gap:10px;
      }
      .jobListing-cards{
        padding-left:15px;
        padding-right:15px;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap:10px;
       
        .job-listing-result-nums{
            padding:5px;
            font-size: 12px;
            font-weight: 510;
            .result-num{
              color: darkgreen;
            }
        }

        .job-card{
          padding:10px;
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          padding-left: 10px;
          gap:10px;
          border-width: 0.2px;
          border-style: solid;
          border-color: grey;
         
          .job-card-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: rgb(243, 248, 248);
            .title-heading{
              padding:5px;
              font-size: 12px;
              font-weight: 510;
            } 
            .title-postedDate{
              padding-right:25px;
              font-size: 12px;
              font-weight: 510;
            }
          }
          .job-card-details{
            display: flex;
            flex-direction: row;
            justify-content: left;
            margin-left: 10px;
            gap:10px;

            .job-card-details-size-sub-heading{
              padding-left: 20px;
              font-size: 10px;
              font-weight: 600;
              color: black;
              text-align: end;
              width: 75px;              
            }

            .job-card-details-size{
              padding-left: 20px;
              padding-right: 20px;
              font-size: 12px;
              font-weight: 600;
              color: rgb(156, 151, 151);

              border-right: 2px solid;
            }
            .job-card-details-size-no-border{
              padding-left: 20px;
              padding-right: 20px;
              font-size: 12px;
              font-weight: 600;
              color: rgb(156, 151, 151);
            } 
            .job-card-details-keywords{
              padding-left: 20px;
              padding-right: 20px;
              font-size: 12px;
              font-weight: 600;
              color: rgb(156, 151, 151);
            }                                                      
          }
          .job-card-keywords{
            padding:4px;
            background-color: green;
          }              


        }
      }    
    }

}