.resumeResultsCard-wrapper {
    margin-top: 40px;
    
    display: flex;
    flex-direction: column;
    gap: 1px;
    border: 1px solid #c3c3c3;
    padding: 10px;

    .resumeResultsCard-name{
        padding: 4px;
        display: flex;
        flex-direction: row;
        font-family: Arial, Helvetica, sans-serif;
        justify-content: space-between;
        
        .resumeResultsCard-name-row{
            font-size: 12px;
            font-weight: 500;    
        }
    }
    .resumeResultsCard-current{
        padding: 4px;
        display: flex;
        flex-direction: column;
        font-family: Arial, Helvetica, sans-serif;
        justify-content: space-between;
        
        .resumeResultsCard-current-column{
            font-size: 12px;
            font-weight: 500;    
        }
    }    
    .resumeResultsCard-heading{
        padding: 4px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
        font-weight: 600;  
        background-color: #ebecf4;
    }
    .resumeResultsCard-skills{
        padding: 4px;
        background-color:rgb(253, 253, 253);
        font-size: 12px;
    }

    .resumeResultsCard-Jds-Table{
        padding: 4px;
        display: flex;
        flex-direction: row;
        font-family: Arial, Helvetica, sans-serif;
        justify-content: space-between;
        
        .resumeResultsCard-Jds-Table-column{
            font-size: 12px;
            font-weight: 500;    
        }
    }
    .resumeResultsCard-othes{
        padding: 4px;
    }
}

